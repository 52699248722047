.sidebar {
  z-index: 299;
  width: 100%;
  /* background: #fff; */

  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
}

.sidebar .ant-layout.ant-layout-has-sider {
  flex-direction: row;
  height: 100vh;
  padding: 0px !important;
  margin: 0px !important;
}

.sidebar .demo-logo-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 10px;
  min-height: 70px;
}
