.header .logo {
    width: 135px;
    height: 50px;
}

.header .icons-container {
    display: flex; 
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}

@media screen and (max-width: 767px) {
    .header .ant-layout-content {
        padding: 5px 10px !important;
    }
    .header .logo {
        width: 100px;
        height: 40px;
    }
    .header p {
        margin-top: 7px;
    }

    .header .icons-container {
        padding-right: 0px;
    }
}

@media screen and (max-width: 320px) {
    .header .ant-layout-content {
        padding: 2px 5px !important;
    }
    .header .logo {
        width: 75px;
        height: 30px;
    }
    .header p {
        margin-top: 5px;
    }

    .header .icons-container {
        display: flex; 
        justify-content: flex-end;
        align-items: center
    }
}