/* Styles for login page */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .login-content {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form {
    width: 100%;
  }
  
  .input-field {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .login-button {
    width: 100%;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 600px) {
    .login-content {
      padding: 15px;
    }
  }

  @media screen and (max-width: 800px) {
    .login-content {
      padding: 15px;
    }
  }
  