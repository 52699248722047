@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-layout-content {
    padding: 18px 32px !important;
    margin: 5px 0 0 !important;
}

body {
    display: block;
    margin: 0px !important;
}